import { formatDate } from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http'
import { Injectable, NgZone } from '@angular/core'
import {
  Device,
  DeviceInfo,
  DeviceSortItemProperty,
  DeviceTypeInformation,
  LastTrafficlightsResponse,
  ServiceIds,
  SortOption,
  SortOrder,
} from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { BehaviorSubject, Subject, debounceTime, tap } from 'rxjs'
import { IPCIconListGroup, IPCIconListItem } from 'src/app/modules/shared/components/buttons/pc-icon-dropdown/pc-icon-dropdown.component'
import { DeviceStore } from '../stores/device.store'

export interface DeviceTypeInformationResponse {
  device_information: DeviceTypeInformation[]
}

export interface ServiceInformationResponse {
  services: ServiceInformation[]
}

export interface ServiceInformation {
  device_types: string[]
  id: string
}

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  // Fires whenever a device got deleted. This is for other services who needs to know
  public deviceDeleted$ = new BehaviorSubject<DeviceInfo | null>(null)

  public deviceInfo: DeviceTypeInformationResponse
  public serviceDeviceTypeInformation: ServiceInformationResponse
  public devices: DeviceInfo[] = []
  public shownDevices: DeviceInfo[] = []
  public filteredDevices: DeviceInfo[] = []
  public devicesWithErrorCount = 0

  private deviceAugmentationIntervalId?: ReturnType<typeof setTimeout>
  private deviceOnlineStatusesIntervalId?: ReturnType<typeof setTimeout>

  // Would be nicer in the store but probably has to go here
  public devicesUpdated$ = new Subject<void>()
  public sortDevices$ = new Subject<SortOption>()
  public refreshShownDevicesRequired$ = new Subject<void>()
  public reFilterAndSort$ = new Subject<void>()
  private reFilterAndSortTap = this.reFilterAndSort$
    .pipe(
      tap(() => {
        // Reset filtered to whole list (is deep copy necessary?)
        this.filteredDevices = this.devices

        // Search string
        const query = this.searchInput$.getValue()
        if (query) {
          this.filterDevicesByQuery(query)
        }

        // Filter toggles
        this.filterOptions.forEach((option) => {
          option.items.forEach((item) => {
            this.updateFilterItem(item)
          })
        })

        // Sorting
        if (this.currentSortOption) {
          this.sortDevices$.next(this.currentSortOption)
        }

        // pagination
        this.refreshShownDevicesRequired$.next()
      })
    )
    .subscribe()

  public deviceEdit$ = new Subject<DeviceInfo | false>()

  public visibleMenuDevice?: DeviceInfo

  public showAddDeviceOverlay$ = new Subject<{
    show: boolean
    parentEndpointId?: string
    virtual?: boolean
  }>()

  public showDeleteDeviceOverlay$ = new Subject<DeviceInfo | false>()

  public showAutomaticFirmwareUpdateOverlay$ = new Subject<DeviceInfo | false>()

  public showVirtualUuid$ = new Subject<string | false>()

  public showGrantAccessOverlay$ = new Subject<string | false>()

  // Check and give a better name
  public showAddDeviceGroup$ = new Subject<
    | {
        show: boolean
      }
    | false
  >()

  public mapCenter = {
    lat: 49.418579,
    lng: 11.118095,
    default: true,
  }

  public virtualCSVDevices: Record<string, string> = {}

  public deviceSelected$ = new Subject<DeviceInfo | false>()

  public lastExpandedDevice$ = new Subject<DeviceInfo | false>()

  private lastExpandedTab$ = this.lastExpandedDevice$.pipe(
    tap((device) => {
      if (device) {
        if (!device.metadata.uuid) {
          return
        }
        window.location.hash = device.metadata.uuid
      } else {
        window.location.hash = ''
      }
    })
  )

  public sortDeviceTap$ = this.sortDevices$.pipe(
    tap((sortOption) => {
      if (!this.filteredDevices) {
        return
      }
      this.sortDevicesBy(this.filteredDevices, sortOption)
      this.refreshShownDevicesRequired$.next()
    })
  )

  public searchInput$ = new BehaviorSubject<string>('')

  public searchTap$ = this.searchInput$.pipe(
    debounceTime(200),
    tap((query) => {
      this.currentQuery = query
      this.reFilterAndSort$.next()
    })
  )

  deviceTypes: string[] = []

  filterOptions: IPCIconListGroup[] = [
    {
      id: 'state',
      label: 'State',
      items: [
        {
          name: 'online',
          value: 'Online',
          selectable: true,
          selected: true,
        },
        {
          name: 'offline',
          value: 'Offline',
          selectable: true,
          selected: true,
        },
      ],
    },
    {
      id: 'health',
      label: 'Health',
      items: [
        {
          name: 'regular',
          value: 'Regular',
          selectable: true,
          selected: true,
        },
        {
          name: 'alert',
          value: 'Alert',
          selectable: true,
          selected: true,
        },
        {
          name: 'attention',
          value: 'Attention',
          selectable: true,
          selected: true,
        },
      ],
    },
    {
      id: 'device-type',
      label: 'Device Type',
      items: [],
    },
  ]

  public settings = {
    devicePollingInterval: 1000 * 30, // 30 seconds
    deviceOnlinePollingInterval: 1000 * 5, // 5 seconds
    devicePollingActive: false,
  }

  private deviceDisplayItems = [
    {
      label: 'Connection Status',
      key: 'con_connected',
      falseyMessage: 'Offline',
      truthyMessage: 'Online',
    },
    {
      label: 'Device Status',
      key: 'trafficLightMessage',
    },
    {
      label: 'Device Type',
      key: 'con_DeviceType',
    },
    {
      label: 'Created',
      key: 'createdDate',
      notMeta: true,
      type: 'date',
    },
    {
      label: 'Last seen',
      key: 'con_connected_ts',
      type: 'date',
    },
    {
      label: 'Serial Number',
      key: 'con_SerialNumber',
    },
    {
      label: 'Firmware Version',
      key: 'con_FirmwareVersion',
    },
    {
      label: 'Hardware Version',
      key: 'con_HardwareVersion',
    },
    {
      label: 'UUID',
      key: 'uuid',
    },
    {
      label: 'Comment',
      key: 'comment',
      falseyMessage: 'No comment added yet',
    },
  ]

  sortOptions: SortOption[] = [
    {
      property: 'trafficLightNumber',
      label: 'DEVICE.SORT_OPTION_STATUS',
      shortLabel: 'DEVICE.SORT_OPTION_STATUS_SHORT',
      order: 'desc',
      defaultOrder: 'desc',
    },
    {
      property: 'deviceName',
      label: 'DEVICE.SORT_OPTION_DEVICE_NAME',
      order: 'asc',
      shortLabel: 'DEVICE.SORT_OPTION_DEVICE_NAME_SHORT',
    },
    {
      property: 'con_DeviceType',
      label: 'DEVICE.SORT_OPTION_DEVICE_TYPE',
      order: 'asc',
      shortLabel: 'DEVICE.SORT_OPTION_DEVICE_TYPE_SHORT',
    },
  ]

  // Note: Don't assign this if we don't want items to be initially
  currentSortOption: SortOption = this.sortOptions[0]
  currentQuery = ''

  constructor(
    private deviceStore: DeviceStore,
    private ngZone: NgZone,
    private proficloud: ProficloudService
  ) {
    // Reset filter options on org switch
    this.proficloud.organisationSwitched$.subscribe(() => {
      this.filterOptions.forEach((group) =>
        group.items.forEach((item) => {
          item.selected = true
        })
      )
      this.deviceTypes.length = 0
      this.filterOptions = this.obtainDeviceTypes()
    })

    this.deviceStore.getDeviceServicesInfo().subscribe((deviceInfoResponse) => {
      this.serviceDeviceTypeInformation = deviceInfoResponse
    })

    this.deviceStore.getDeviceTypeInfo().subscribe((serviceAvailableDevicesResponse) => {
      this.deviceInfo = serviceAvailableDevicesResponse
    })

    // Start polling once store is initialised
    this.deviceStore.storeInitialised$.subscribe((errored) => {
      if (!errored) {
        this.setupPolling()
      }
    })

    // devices$ will trigger basically only on organisation switch so we can refresh the whole list
    // TODO: Handle error
    this.deviceStore.devices$.subscribe((devices) => {
      this.devices = devices // All devices
      this.filteredDevices = this.devices // Filtered on any present criteria
      this.shownDevices = this.filteredDevices.slice(0, 25) || [] // Paginated (and filtered)

      this.countDevicesWithError()

      // set default css
      this.devices.forEach((device) => {
        device.expansionState = 'collapsed'
        this.setFormattedValues(device)
        this.ensureTrafficLight(device)

        // create default logs
        const logsStartDate = new Date()
        logsStartDate.setDate(logsStartDate.getDate() - 1)
        const logsEndDate = new Date()
        device.customLogs = {
          range: {
            start: logsStartDate,
            end: logsEndDate,
          },
          query: '',
          logs: [],
        }
      })

      this.reFilterAndSort$.next()
    })

    this.proficloud.loggedOut$.subscribe(() => {
      this.clearAllPolling()
    })
  }

  private periodicallyFetchDeviceOnlineStatuses() {
    this.deviceOnlineStatusesIntervalId = setInterval(() => {
      // return early if device polling is de-activated
      if (!this.settings.devicePollingActive) {
        return
      }

      // return early if we don't have loaded devices yet
      if (!this.devices) {
        return
      }

      this.deviceStore.getAllDevicesMetaData().subscribe({
        next: (data) => {
          // Endpoint online/offline status and deviceType
          data.content.forEach((content) => {
            if (!this.devices) {
              return
            }
            const device = this.devices.find((ep) => ep.endpointId === content.endpointId)

            if (device) {
              device.metadata.con_connected = content.metadata.con_connected
              device.metadata.con_FirmwareVersion = content.metadata.con_FirmwareVersion
              device.metadata.con_DeviceType = content.metadata.con_DeviceType
              device.metadata.con_connected_ts = content.metadata.con_connected_ts

              this.ensureTrafficLight(device)
              this.setFormattedValues(device)
            }
          })
          this.devicesUpdated$.next()
        },
        error: (err: HttpErrorResponse) => {
          this.proficloud.logoutOnUnauthorised(err)
        },
      })
    }, this.settings.deviceOnlinePollingInterval)
  }

  public filterDevicesByQuery(query: string) {
    this.currentQuery = query
    if (!this.filteredDevices) {
      return
    }

    const visibleParentDevices: DeviceInfo[] = []

    this.filteredDevices = this.filteredDevices.filter((device) => {
      const deviceText = JSON.stringify(device.metadata)
      const spaces = /\s+/g
      const trimmed = query.trim().split(spaces).join(' ')
      const visible = deviceText.toLowerCase().includes(trimmed.toLowerCase())

      // if the device is filtered in and as parent, let record the parent so we can force-filter it in later
      if (visible && device.metadata.parent_endpointID) {
        const parent = this.filteredDevices.find((d) => d.endpointId === device.metadata.parent_endpointID)
        if (parent) {
          visibleParentDevices.push(parent)
        }
      }
      return visible
    })

    // now force filter in all parents
    visibleParentDevices.forEach((parent) => {
      if (!this.filteredDevices.map((d) => d.endpointId).includes(parent.endpointId)) {
        this.filteredDevices.push(parent)
      }
    })
  }

  private sortDevicesBy(devices: DeviceInfo[], sortOption: SortOption) {
    this.currentSortOption = sortOption

    function sortByStatus(device1: DeviceInfo, device2: DeviceInfo, order: SortOrder) {
      const deviceOneTrafficLight = device1.metadata.trafficLightNumber || 0
      const deviceTwoTrafficLight = device2.metadata.trafficLightNumber || 0
      const deviceOneOnline = Number(device1.metadata.con_connected || false)
      const deviceTwoOnline = Number(device2.metadata.con_connected || false)

      if (order === 'asc') {
        return deviceOneOnline - deviceTwoOnline || deviceOneTrafficLight - deviceTwoTrafficLight
      } else {
        return deviceTwoOnline - deviceOneOnline || deviceTwoTrafficLight - deviceOneTrafficLight
      }
    }

    if (sortOption.label === 'DEVICE.SORT_OPTION_STATUS') {
      devices.sort((d1, d2) => {
        return sortByStatus(d1, d2, sortOption.order)
      })
    } else if (sortOption.label === 'DEVICE.SORT_OPTION_DEVICE_NAME') {
      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
      devices.sort((a, b) => {
        const comparison = collator.compare(a.metadata.deviceName, b.metadata.deviceName)
        return comparison * (sortOption.order === 'asc' ? 1 : -1)
      })
    } else {
      devices.sort((d1, d2) => {
        const deviceOneMetric = d1.metadata[sortOption.property as DeviceSortItemProperty]?.toString().toLowerCase() || ''
        const deviceTwoMetric = d2.metadata[sortOption.property as DeviceSortItemProperty]?.toString().toLowerCase() || ''

        if (deviceOneMetric === '' && deviceTwoMetric === '') {
          return 0
        }

        if (deviceOneMetric === deviceTwoMetric) {
          /*
              Sub-sort by Status descending
              If we don't enforce this then we get flip flop behavior due to
              (I think) the randomisation factor of quick sort.
          */
          return sortByStatus(d1, d2, 'desc')
        }
        if (sortOption.order === 'asc') {
          return deviceOneMetric < deviceTwoMetric ? 1 : -1
        } else {
          return deviceOneMetric > deviceTwoMetric ? 1 : -1
        }
      })
    }
  }

  public obtainDeviceTypes() {
    // get unique device types for current devices list
    this.devices?.forEach((item) => {
      if (item.metadata.con_DeviceType) {
        if (!this.deviceTypes.includes(item.metadata.con_DeviceType)) {
          this.deviceTypes.push(item.metadata.con_DeviceType)
        }
      } else if (!item.metadata.con_DeviceType) {
        if (!this.deviceTypes.includes('Unknown')) {
          this.deviceTypes.push('Unknown')
        }
      }
    })
    // add the obtained device types to filterOptions list
    const devlist = this.filterOptions.find((item) => item.id === 'device-type')
    if (devlist) {
      devlist.items = this.deviceTypes.map((value) => ({
        name: value,
        value: value,
        selectable: true,
        selected: true,
      }))
    }

    return this.filterOptions
  }

  public updateFilterItem(filterItem: IPCIconListItem) {
    this.filterOptions.forEach((group) => {
      const item = group.items.find((i: IPCIconListItem) => i.value === filterItem.value)
      if (item) {
        item.selected = filterItem.selected
      }

      if (group.id === 'state') {
        group.items.forEach((i) => {
          switch (i.value) {
            case 'Online':
              if (!i.selected) {
                this.filteredDevices = this.filteredDevices.filter((item) => item.metadata.con_connected_formatted !== 'Online')
              }
              break
            case 'Offline':
              if (!i.selected) {
                this.filteredDevices = this.filteredDevices.filter((item) => item.metadata.con_connected_formatted !== 'Offline')
              }
              break
          }
        })
      }

      if (group.id === 'health') {
        group.items.forEach((i) => {
          switch (i.value) {
            case 'Regular':
              if (!i.selected) {
                this.filteredDevices = this.filteredDevices.filter((item) => item.metadata.trafficLightNumber !== 0)
              }
              break
            case 'Attention':
              if (!i.selected) {
                this.filteredDevices = this.filteredDevices.filter((item) => item.metadata.trafficLightNumber !== 1)
              }
              break
            case 'Alert':
              if (!i.selected) {
                this.filteredDevices = this.filteredDevices.filter((item) => item.metadata.trafficLightNumber !== 2)
              }
              break
          }
        })
      }

      if (group.id === 'device-type') {
        group.items.forEach((i) => {
          this.filteredDevices.forEach((device) => {
            if (device.metadata.con_DeviceType == i.value && !i.selected) {
              this.filteredDevices = this.filteredDevices.filter((item) => item.metadata.con_DeviceType !== i.value)
            } else if (device.metadata.con_DeviceType_formatted === 'Device Type not available' && i.value === 'Unknown' && !i.selected) {
              this.filteredDevices = this.filteredDevices.filter((item) => item.metadata.con_DeviceType_formatted !== 'Device Type not available')
            }
          })
        })
      }
    })
  }

  // Note: One day everything will come through SSE streams, one might hope
  public setupPolling() {
    if (!this.deviceOnlineStatusesIntervalId) {
      this.periodicallyFetchDeviceStatuses()
      this.periodicallyFetchDeviceOnlineStatuses()
    }
  }

  // Polling
  public clearAllPolling() {
    clearInterval(this.deviceAugmentationIntervalId)
    delete this.deviceAugmentationIntervalId
    clearInterval(this.deviceOnlineStatusesIntervalId)
    delete this.deviceOnlineStatusesIntervalId
  }

  private periodicallyFetchDeviceStatuses() {
    this.ngZone.runOutsideAngular(() => {
      // trigger augmentations before the first interval
      this.performDeviceAugmentations()

      this.deviceAugmentationIntervalId = setInterval(() => {
        // only run a new command, if no "old" request is open to avoid system overload (lol)
        if (this.settings.devicePollingActive) {
          this.performDeviceAugmentations()
        }
      }, this.settings.devicePollingInterval)
    })
  }

  private performDeviceAugmentations() {
    this.fetchLastEndpointHealthStatus()?.subscribe({
      next: (trafficLights) => {
        this.handleAugmentationResults(trafficLights)
      },
      error: (err) => {
        this.proficloud.logoutOnUnauthorised(err)
      },
    })
  }

  private fetchLastEndpointHealthStatus() {
    if (this.shownDevices.length) {
      const endpointIds = this.shownDevices.map((device) => device.endpointId).join(',')
      const metricName = 'con_trafficlights'
      // eslint-disable-next-line max-len
      return this.deviceStore.getLastDeviceHealthStatus(endpointIds, metricName)
    }
  }

  private handleAugmentationResults(trafficLights: LastTrafficlightsResponse) {
    // Note: This happens in testing sometimes with mocked data
    if (!trafficLights) {
      return
    }

    // Note: This can happen as a race condition if the user logs out just as the data is being fetched but before it's processed
    if (!this.devices) {
      console.warn('devices not loaded')
      return
    }

    // health status now comes from last log entry
    Object.keys(trafficLights).forEach((endpointId) => {
      const device = this.devices?.find((ep) => ep.endpointId === endpointId)

      // If the device has just been added, it is possible that it won't be in kaaData.devices yet
      if (device) {
        const data = trafficLights[endpointId].con_trafficlights[0].values
        device.metadata.trafficLightNumber = data.con_TrafficLightColor
        device.metadata.trafficLightMessage = data.con_TrafficLightMessage
      }
    })

    this.devices.forEach((device) => {
      this.ensureTrafficLight(device)
      this.setFormattedValues(device)
    })

    // Re-apply existing sorting and filtering if present
    this.reFilterAndSort$.next()

    // Signal devices updated (don't like this so much)
    this.devicesUpdated$.next()
  }

  public setFormattedValues(device: DeviceInfo) {
    this.deviceDisplayItems.forEach((item) => {
      const val = item.notMeta ? device[item.key as 'expanded'] : device.metadata[item.key as 'con_ArticleNumber']
      device.metadata[(item.key + '_formatted') as 'con_connected_formatted'] = (val as string) || `${item.label} not available`

      if (item.falseyMessage && !val) {
        device.metadata[(item.key + '_formatted') as 'createdDate_formatted'] = item.falseyMessage
      }
      if (item.truthyMessage && val) {
        device.metadata[(item.key + '_formatted') as 'createdDate_formatted'] = item.truthyMessage
      }
      if (item.type === 'date' && val) {
        device.metadata[(item.key + '_formatted') as 'createdDate_formatted'] = formatDate(val as any, 'MMM d yyyy, HH:mm', 'en_EN')
      }
      if (item.key === 'con_connected_ts' && device.metadata.con_connected) {
        device.metadata[(item.key + '_formatted') as 'createdDate_formatted'] = 'Device is currently online'
      }
    })
  }

  public ensureTrafficLight(device: DeviceInfo) {
    if (!device.metadata.trafficLightNumber) {
      device.metadata.trafficLightNumber = 0
    }
  }

  public serviceAvailableDevices(service: ServiceIds, virtual: boolean = false) {
    const serviceEntry = this.serviceDeviceTypeInformation?.services?.find((s) => s.id === service)
    const devices: DeviceTypeInformation[] = []
    if (serviceEntry?.device_types) {
      for (const device_type of serviceEntry.device_types) {
        const device = this.deviceInfo?.device_information?.find((d) => d.device_type === device_type)
        if (!device) {
          return
        }
        if (virtual) {
          // push all devices to result list if virtual should be included
          devices.push(device)
        } else if (!device.virtual) {
          // push all non-virtual devices only to list
          devices.push(device)
        }
      }
    }
    return devices
  }

  public deviceAvailableServices(device: Device): ServiceIds[] {
    const deviceEntry = this.deviceInfo?.device_information?.find((d) => d.device_type === device?.metadata?.con_DeviceType)
    if (!deviceEntry) {
      return [] as ServiceIds[]
    }
    return deviceEntry.services
  }

  public deviceIsTsdCapable(device: Device) {
    const deviceEntry = this.deviceInfo?.device_information?.find((d) => d.device_type === device?.metadata?.con_DeviceType)
    if (!deviceEntry) {
      return false
    }
    return deviceEntry.services.includes('tsd')
  }

  public deviceIsIaCapable(device: Device) {
    const deviceEntry = this.deviceInfo?.device_information?.find((d) => d.device_type === device?.metadata?.con_DeviceType)
    if (!deviceEntry) {
      return false
    }
    return deviceEntry.services.includes('ia')
  }

  public deviceIsEmmaCapable(device: Device) {
    const deviceEntry = this.deviceInfo?.device_information?.find((d) => d.device_type === device?.metadata?.con_DeviceType)
    if (!deviceEntry) {
      return false
    }
    return deviceEntry.services.includes('emma')
  }

  public deviceHasNoServices(device: Device) {
    return !this.deviceIsTsdCapable(device) && !this.deviceIsIaCapable(device) && !this.deviceIsEmmaCapable(device)
  }

  public deviceIsGateway(device: DeviceInfo) {
    const deviceEntry = this.deviceInfo?.device_information?.find((d) => d.device_type === device?.metadata?.con_DeviceType)
    if (!deviceEntry) {
      return false
    }
    return deviceEntry.gateway
  }

  public deviceIsVirtual(device: DeviceInfo) {
    const deviceEntry = this.deviceInfo?.device_information?.find((d) => d.device_type === device?.metadata?.con_DeviceType)
    if (!deviceEntry) {
      return true
    }
    return deviceEntry.virtual
  }

  private countDevicesWithError() {
    if (!this.devices) {
      return
    }
    this.devicesWithErrorCount = this.devices.filter((ep) => {
      return ep.metadata.trafficLightNumber === 2
    }).length
  }

  public toggleDeviceExpansionState(device: DeviceInfo, options = { forceExpand: false, closeOthers: false }) {
    // TODO: This might need to be for filteredDevices or call a refresh
    if (options.closeOthers) {
      this.devices.forEach((dev) => {
        dev.expansionState = 'collapsed'
      })
    }
    const currentState = options.forceExpand ? 'collapsed' : device.expansionState
    const nextState = currentState === 'expanded' ? 'collapsed' : 'expanded'
    const payload = nextState === 'expanded' ? device : false
    device.expansionState = nextState

    this.lastExpandedDevice$.next(payload)
  }

  public onDeviceMapClick($event: MouseEvent, device: DeviceInfo) {
    // Center map around device
    if (device.metadata.lat && device.metadata.lng) {
      this.mapCenter.lat = device.metadata.lat
      this.mapCenter.lng = device.metadata.lng
    }

    $event.stopPropagation()
    this.deviceSelected$.next(device)
  }

  public onMapMarkerClicked($event: MouseEvent, device: DeviceInfo) {
    this.deviceSelected$.next(device)
  }

  public mapCenterChange(mapCenter: { lng: number; lat: number }) {
    this.mapCenter.lat = mapCenter.lat
    this.mapCenter.lng = mapCenter.lng
  }

  public deviceIsVirtualCSV(endpointId: string) {
    return !!this.virtualCSVDevices[endpointId]
  }

  public getDeviceName(endpointId: string) {
    const device = this.getDeviceByEndpointId(endpointId)
    if (device) {
      return device.metadata.deviceName
    } else if (this.deviceIsVirtualCSV(endpointId)) {
      return endpointId
    } else {
      return 'none'
    }
  }

  public getDeviceByEndpointId(endpointId: string) {
    if (this.devices) {
      return this.devices.find((d) => d && d.endpointId === endpointId)
    }
  }

  public findDeviceByUuid(uuid: string) {
    return this.devices?.find((device) => device.metadata.uuid === uuid)
  }

  public endpointIDsToDevices(endpointIDs: string[]): DeviceInfo[] {
    if (this.devices) {
      return endpointIDs
        .map((id) => {
          return this.devices?.find((d) => d.endpointId === id)
        })
        .filter((d) => !!d) as DeviceInfo[]
    } else {
      return []
    }
  }

  public hasValidLatLng(device: DeviceInfo) {
    return !isNaN(Number(device.metadata.lat)) && !isNaN(Number(device.metadata.lng))
  }

  // TODO: Put in http layer
  public addUUIDtoDB(uuid: string, apiSecret: string) {
    //   const headers = {
    //     headers: new HttpHeaders(this.getPrivateApiHeaders(apiSecret)),
    //   }
    //   const url = `${this.backendUrls.middlewareUrl}/device/deviceRegistration`
    //   const payload = {
    //     device_uuid: uuid,
    //     device_type: 'E2E Test Device',
    //     material_number: 'Test',
    //     business_unit: 'Proficloud',
    //   }
    //   return this.http.post(url, payload, headers)
  }
}
