import { Injectable } from '@angular/core'
import { AppService } from 'src/app/app.service'

@Injectable({
  providedIn: 'root',
})
export class HttpBaseService {
  backendUrls: {
    deviceLifecycleUrl: string
    deviceEventsUrl: string
    deviceBillingUrl: string
    apiTsdBaseUrl: string
    middlewareUrl: string
    firmwareUrl: string
    applicationUrl: string
    updateManagementUrl: string
    dmsAlertingUrl: string
    emmaUrl: string
    emmaSimulatorUrl: string
    emmaImportCsvUrl: string
    emmaNotificationUrl: string
    chargeRepayUrl?: string
    chargeRepayImUrl?: string
    authBaseUrl: string
    grafanaBaseUrl: string
    grafanaBaseUrlEmpro: string
    // billing
    billingBaseUrl: string
    // services
    tsdServiceUrl: string
    iaServiceUrl: string
    // device information hub
    DeviceTypeInformationUrl: string
    landingUrl: string
    iamUrl: string
    deviceGroupUrl: string
    notificationServiceUrl: string
    rbacServiceUrl: string
    // admin service
    adminServiceUrl: string
  }

  urls = {
    local: {
      // new dev envs
      // kaa
      deviceLifecycleUrl: 'https://env.kaa.proficloud-development.io/device-lifecycle-management/api/v1',
      deviceEventsUrl: 'https://env.kaa.proficloud-development.io/device-lifecycle-management/api/v1/sse',
      deviceBillingUrl: 'https://env.kaa.proficloud-development.io/devicebp',
      apiTsdBaseUrl: 'https://env.kaa.proficloud-development.io/epts-adapter/api/v1',
      // middleware
      middlewareUrl: 'https://env.kaa.proficloud-development.io/middleware',
      firmwareUrl: 'https://env.kaa.proficloud-development.io/firmware',
      // application management service
      applicationUrl: 'https://env.kaa.proficloud-development.io/ams',
      updateManagementUrl: 'https://env.kaa.proficloud-development.io/update-management',
      dmsAlertingUrl: 'https://env.kaa.proficloud-development.io/dcas',
      // emmaUrl: 'https://env.kaa.proficloud-development.io/emma',
      emmaUrl: 'http://localhost:5000',
      emmaSimulatorUrl: 'http://localhost:8080/empro-device-simulator',
      emmaImportCsvUrl: 'http://localhost:5555',
      emmaNotificationUrl: 'http://localhost:5556',
      // keycloak
      authBaseUrl: 'https://auth.proficloud-dev.io/auth',
      grafanaBaseUrl: 'https://tsd.proficloud-dev.io/d/device_basic_template/device-template?var-metrics=All&var-uuid=',
      grafanaBaseUrlEmpro: 'https://tsd.proficloud-dev.io/d/empro_template/empro-template?var-Zeitbereich=1h&var-uuid=',
      // billing
      billingBaseUrl: 'https://env.kaa.proficloud-development.io/billing-proxy',
      // services
      tsdServiceUrl: 'https://tsd.proficloud-dev.io',
      iaServiceUrl: 'https://impulseanalytics.proficloud-dev.io',
      // device information hub
      DeviceTypeInformationUrl: 'https://env.kaa.proficloud-development.io/dics/v2',
      landingUrl: 'https://proficloud-dev.io',
      chargeRepayUrl: 'https://env.kaa.proficloud-development.io/emobility',
      chargeRepayImUrl: 'https://env.kaa.proficloud-development.io/charge-repay-installation-manager',
      iamUrl: 'https://env.kaa.proficloud-development.io/middleware/iam',
      deviceGroupUrl: 'https://env.kaa.proficloud-development.io/device-group',
      notificationServiceUrl: 'https://env.kaa.proficloud-development.io/notification-service',
      rbacServiceUrl: 'https://env.kaa.proficloud-development.io/device-rbac',
      // admin service
      adminServiceUrl: 'https://env.kaa.proficloud-development.io/admin/api',
    },

    dev: {
      // new dev envs
      // kaa
      deviceLifecycleUrl: 'https://env.kaa.proficloud-development.io/device-lifecycle-management/api/v1',
      deviceEventsUrl: 'https://env.kaa.proficloud-development.io/device-lifecycle-management/api/v1/sse',
      deviceBillingUrl: 'https://env.kaa.proficloud-development.io/devicebp',
      apiTsdBaseUrl: 'https://env.kaa.proficloud-development.io/epts-adapter/api/v1',
      // middleware
      middlewareUrl: 'https://env.kaa.proficloud-development.io/middleware',
      firmwareUrl: 'https://env.kaa.proficloud-development.io/firmware',
      // application management service
      applicationUrl: 'https://env.kaa.proficloud-development.io/ams',
      updateManagementUrl: 'https://env.kaa.proficloud-development.io/update-management',
      dmsAlertingUrl: 'https://env.kaa.proficloud-development.io/dcas',
      emmaUrl: 'https://env.kaa.proficloud-development.io/emma',
      emmaSimulatorUrl: 'https://env.kaa.proficloud-development.io/empro-device-simulator',
      emmaImportCsvUrl: 'https://env.kaa.proficloud-development.io/emma/emma-import-csv',
      emmaNotificationUrl: 'https://env.kaa.proficloud-development.io/emma/emma-alerting',
      // emmaImportCsvUrl: 'http://localhost:5555',
      // emmaUrl: 'http://0.0.0.0:5000',
      // keycloak
      authBaseUrl: 'https://auth.proficloud-dev.io/auth',
      grafanaBaseUrl: 'https://tsd.proficloud-dev.io/d/device_basic_template/device-template?var-metrics=All&var-uuid=',
      grafanaBaseUrlEmpro: 'https://tsd.proficloud-dev.io/d/empro_template/empro-template?var-Zeitbereich=1h&var-uuid=',
      // billing
      billingBaseUrl: 'https://env.kaa.proficloud-development.io/billing-proxy',
      // services
      tsdServiceUrl: 'https://tsd.proficloud-dev.io',
      iaServiceUrl: 'https://impulseanalytics.proficloud-dev.io',
      // device information hub
      DeviceTypeInformationUrl: 'https://env.kaa.proficloud-development.io/dics/v2',
      landingUrl: 'https://proficloud-dev.io',
      chargeRepayUrl: 'https://env.kaa.proficloud-development.io/emobility',
      chargeRepayImUrl: 'https://env.kaa.proficloud-development.io/charge-repay-installation-manager',
      iamUrl: 'https://env.kaa.proficloud-development.io/middleware/iam',
      deviceGroupUrl: 'https://env.kaa.proficloud-development.io/device-group',
      notificationServiceUrl: 'https://env.kaa.proficloud-development.io/notification-service',
      rbacServiceUrl: 'https://env.kaa.proficloud-development.io/device-rbac',
      // admin service
      adminServiceUrl: 'https://env.kaa.proficloud-development.io/admin/api',
    },

    staging: {
      // new staging envs
      // kaa
      deviceLifecycleUrl: 'https://env.kaa.proficloud-staging.io/device-lifecycle-management/api/v1',
      deviceEventsUrl: 'https://env.kaa.proficloud-staging.io/device-lifecycle-management/api/v1/sse',
      deviceBillingUrl: 'https://env.kaa.proficloud-staging.io/devicebp',
      apiTsdBaseUrl: 'https://env.kaa.proficloud-staging.io/epts/api/v1',
      // middleware
      middlewareUrl: 'https://env.kaa.proficloud-staging.io/middleware',
      firmwareUrl: 'https://env.kaa.proficloud-staging.io/firmware',
      // application management service
      applicationUrl: 'https://env.kaa.proficloud-staging.io/ams',
      updateManagementUrl: 'https://env.kaa.proficloud-staging.io/update-management',
      dmsAlertingUrl: 'https://env.kaa.proficloud-staging.io/dcas',
      emmaUrl: 'https://env.kaa.proficloud-staging.io/emma',
      emmaSimulatorUrl: 'https://env.kaa.proficloud-staging.io/empro-device-simulator',
      emmaImportCsvUrl: 'https://env.kaa.proficloud-staging.io/emma//emma-import-csv',
      emmaNotificationUrl: 'https://env.kaa.proficloud-staging.io/emma/emma-alerting',
      chargeRepayUrl: 'https://env.kaa.proficloud-staging.io/emobility',
      chargeRepayImUrl: 'https://env.kaa.proficloud-staging.io/charge-repay-installation-manager',
      // emmaUrl: 'http://0.0.0.0:5000',
      // keycloak
      authBaseUrl: 'https://auth.proficloud-staging.io/auth',
      grafanaBaseUrl: 'https://tsd.proficloud-staging.io/d/device_basic_template/device-template?var-metrics=All&var-uuid=',
      grafanaBaseUrlEmpro: 'https://tsd.proficloud-staging.io/d/empro_template/empro-template?var-Zeitbereich=1h&var-uuid=',
      // billing
      billingBaseUrl: 'https://env.kaa.proficloud-staging.io/billing-proxy',
      // services
      tsdServiceUrl: 'https://tsd.proficloud-staging.io',
      iaServiceUrl: 'https://impulseanalytics.proficloud-staging.io',
      // device information hub
      DeviceTypeInformationUrl: 'https://env.kaa.proficloud-staging.io/dics/v2',
      landingUrl: 'https://proficloud-staging.io',
      iamUrl: 'https://env.kaa.proficloud-staging.io/middleware/iam',
      deviceGroupUrl: 'https://env.kaa.proficloud-staging.io/device-group',
      notificationServiceUrl: 'https://env.kaa.proficloud-development.io/notification-service',
      rbacServiceUrl: 'https://env.kaa.proficloud-staging.io/device-rbac',
      // admin service
      adminServiceUrl: 'https://env.kaa.proficloud-staging.io/admin/api',
    },

    production: {
      // kaa
      deviceLifecycleUrl: 'https://env.kaa.proficloud-production.io/device-lifecycle-management/api/v1',
      deviceEventsUrl: 'https://env.kaa.proficloud-production.io/device-lifecycle-management/api/v1/sse',
      deviceBillingUrl: 'https://env.kaa.proficloud-production.io/devicebp',
      apiTsdBaseUrl: 'https://env.kaa.proficloud-production.io/epts/api/v1',
      // middleware
      middlewareUrl: 'https://env.kaa.proficloud-production.io/middleware',
      firmwareUrl: 'https://env.kaa.proficloud-production.io/firmware',
      // application management service
      applicationUrl: 'https://env.kaa.proficloud-production.io/ams',
      updateManagementUrl: 'https://env.kaa.proficloud-production.io/update-management',
      dmsAlertingUrl: 'https://env.kaa.proficloud-production.io/dcas',
      emmaUrl: 'https://env.kaa.proficloud-production.io/emma',
      emmaSimulatorUrl: 'https://env.kaa.proficloud-production.io/empro-device-simulator',
      emmaImportCsvUrl: 'https://env.kaa.proficloud-production.io/emma/emma-import-csv',
      emmaNotificationUrl: 'https://env.kaa.proficloud-production.io/emma/emma-alerting',
      chargeRepayUrl: 'https://env.kaa.proficloud-production.io/emobility',
      chargeRepayImUrl: 'https://env.kaa.proficloud-production.io/charge-repay-installation-manager',
      // emmaUrl: 'http://0.0.0.0:5000',
      // keycloak
      authBaseUrl: 'https://auth.proficloud.io/auth',
      grafanaBaseUrl: 'https://tsd.proficloud.io/d/device_basic_template/device-template?var-metrics=All&var-uuid=',
      grafanaBaseUrlEmpro: 'https://tsd.proficloud.io/d/empro_template/empro-template?var-Zeitbereich=1h&var-uuid=',
      // billing
      billingBaseUrl: 'https://env.kaa.proficloud-production.io/billing-proxy',
      // services
      tsdServiceUrl: 'https://tsd.proficloud.io',
      iaServiceUrl: 'https://impulseanalytics.proficloud.io',
      // device information hub
      DeviceTypeInformationUrl: 'https://env.kaa.proficloud-production.io/dics/v2',
      landingUrl: 'https://proficloud.io',
      iamUrl: 'https://env.kaa.proficloud-production.io/middleware/iam',
      deviceGroupUrl: 'https://env.kaa.proficloud-production.io/device-group',
      notificationServiceUrl: 'https://env.kaa.proficloud-development.io/notification-service',
      rbacServiceUrl: 'https://env.kaa.proficloud-production.io/device-rbac',
      // admin service
      adminServiceUrl: 'https://env.kaa.proficloud-production.io/admin/api',
    },
  }

  constructor(protected app: AppService) {
    this.backendUrls = this.urls[this.app.environment.backend]
  }
}
